import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { Building } from "../classes/building";
import * as mapboxgl from 'mapbox-gl';

@Injectable({
  providedIn: 'root'
})
export class MapboxService {
  url = 'https://api.mapbox.com/styles/v1/mapbox/light-v10/static/0.5099,54.8941,3.1,0/300x200?access_token=pk.eyJ1IjoidG9ueW1wb3J0ZXIiLCJhIjoiY2syZDdkc3E3MDBnYjNtbGszc3M3YjEwdyJ9.aWL57bVL2a7A80tYmnCQ8A';


  accessToken: string;

  constructor() {
    console.log(environment);
    this.accessToken = environment.mapbox.accessToken;
  }

  convertLatLongToMarkers(latlngs: { lat: number, long: number }[]): mapboxgl.Marker[] {
    return latlngs.map(latlng => this.convertLatLongToMarker(latlng.lat, latlng.long));
  }

  convertLatLongToMarker(lat: number, long: number): mapboxgl.Marker {
    const marker = new mapboxgl.Marker();

    if (!lat || !long) {
      return;
    }
    marker.setLngLat({ lat, lng: long });

    return marker;
  }

  getMarkersFromBuildings(buildings: Building[]) {
    return buildings.map(building => {
      return {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [building.site.address.addressLat, building.site.address.addressLong]
        },
        'properties': {
          'message': building.title
        }
      };
    });
  }
}
