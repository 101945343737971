import { License, LicenseBuilding, LicenseGateway } from "./license";

export class LicenseCollection {
	gateways: any[];
	buildings: any[];
	licenses: LicenseBuilding[] | LicenseGateway[];
	counts: {
		all: { l: number, b: number, g: number },
		expired: { l: number, b: number, g: number }
	};

	constructor(data: any) {
		this.gateways = data.gateways;
		this.buildings = data.buildings;
		this.licenses = [
			...data.buildings.map(l => {
				return new LicenseBuilding(l);
			})
			,
			...data.gateways.map(l => {
				return new LicenseGateway(l);
			})];

		this.counts = {
			all: {
				l: this.licenses.length,
				g: this.gateways.length,
				b: this.buildings.length
			},
			expired: {
				l: (<License[]>this.licenses).filter(l => l.hasExpired).length,
				g: null,
				b: null
			}
		};

	}
}
