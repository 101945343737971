import { DataForm } from "app/layout/data-form/data-form.component";
import { Condition } from "./condition";
import { IExplorerSelectedItems } from "app/developer/explorer/explorer.component";
import moment from 'moment';
import { IDateRange } from "app/shared/calendar.service";

export class OrgExport {
	id: number | null;
	title: string;
	dateRange: IDateRange = 'lastweek';
	dates: { from: Date, to: Date } = { from: null, to: null };
	siteIds: number[];
	gatewayIds: string[];
	assetIds: number[];
	assetTypeIds: number[];
	assetTypeNames: string[];
	conditions: Condition[];

	constructor(data?: IOrgExport) {
		this.clear();

		if (data) {
			this.id = data.id;
			this.title = data.title;
			this.siteIds = data.siteIds || [];
			this.assetIds = data.assetIds || [];
			this.assetTypeIds = data.assetTypeIds || [];
			this.gatewayIds = data.gatewayIds || [];
			this.conditions = data.conditions;
			this.dateRange = data.dateRange;
			this.dates = { from: data.dates.from, to: data.dates.to };
		}
	}

	clear() {
		this.id = null;
		this.title = '';
		this.siteIds = [];
		this.gatewayIds = [];
		this.assetIds = [];
		this.assetTypeIds = [];
		this.conditions = [];
		this.dateRange = 'lastweek';
	}

	getConditionAsDataForm(indx: number): DataForm {
		if (!this.conditions || this.conditions.length === 0) {
			this.conditions = [new Condition()];
		}

		const item = this.conditions[indx];

		const dataFormConfig =
		{
			id: item.uuid,
			items: [
				{ id: 'operand', label: 'Condition', value: item.operand, type: 'group', options: [{ id: 'lt', title: 'Less Than', checked: false }, { id: 'gt', title: 'Greater Than', checked: false }, { id: 'eq', title: 'Equal to', checked: false }] },
				{ id: 'value', label: 'Value', type: 'number', value: item.value },
				{ id: 'timespan.from', label: 'From time', type: 'time', value: item.timespan.from },
				{ id: 'timespan.to', label: 'To time', type: 'time', value: item.timespan.to },
				{
					id: 'includePreviousMins', label: 'Previous Minutes', type: 'group', options: [
						{ id: 0, title: '0 Mins', checked: false },
						{ id: 15, title: '15 Mins', checked: false }, { id: 30, title: '30 Mins', checked: false }, { id: 60, title: '1 Hour', checked: false }], value: item.includePreviousMins || 0
				}
			]
		};

		return new DataForm(dataFormConfig)
	}

	setConditionFromDataForm(dataForm: DataForm) {
		const conditionIndx = this.conditions.findIndex(c => c.uuid === dataForm.id);
		if (conditionIndx === -1) {
			return;
		}
		this.conditions[conditionIndx] = new Condition(dataForm);
	}

	setAssetTypesFromArray(assetTypes: number[]) {
		this.assetTypeIds = assetTypes;
	}

	setAssetsFromExplorer(data: IExplorerSelectedItems) {
		this.siteIds = Object.keys(data.sites).filter(key => data.sites[key]).map(id => +id);
		this.gatewayIds = Object.keys(data.gateways).filter(key => data.gateways[key]);
		this.assetIds = Object.keys(data.assets).filter(key => data.assets[key]).map(id => +id);
	}

	serialise() {
		return {
			id: this.id,
			title: this.title,
			dateRange: this.dateRange,
			siteIds: this.siteIds,
			gatewayIds: this.gatewayIds,
			assetIds: this.assetIds,
			conditions: this.conditions,
			assetTypeIds: this.assetTypeIds,
			dates: { from: moment(this.dates.from).format('YYYY-MM-DD'), to: moment(this.dates.to).format('YYYY-MM-DD') }
		};
	}
}

export interface IOrgExport {
	id: number | null;
	title: string;
	dateRange: IDateRange;
	// Empty for all
	siteIds?: number[];
	gatewayIds?: string[];
	assetIds?: number[];
	assetTypeIds?: number[];
	conditions: Condition[];
	dates: { from: Date, to: Date };

}
