import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class UploadService {
	API_URL = 'https://jto01vg31a.execute-api.eu-west-2.amazonaws.com/4d/';

	constructor(private apiService: APIService, private http: HttpClient) { }

	get(qs: string = ''): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${this.API_URL}?${qs}`;
			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	post(body, qs: string = ''): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${this.API_URL}?${qs}`;
			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	getListForUser(qs: string = ''): Promise<any> {
		return new Promise((resolve, reject) => {
			return this.get('a=get_list')
				.then(b => {
					resolve(b);
				});
		});
	}

	getListForOrg(qs: string = ''): Promise<any> {
		return new Promise((resolve, reject) => {
			return this.get('a=get_org_list')
				.then(b => {
					resolve(b);
				});
		});
	}

	requestOCR(qs: string = ''): Promise<any> {
		return new Promise((resolve, reject) => {
			return this.get(`a=request_ocr&${qs}`)
				.then(b => {
					resolve(b);
				});
		});
	}

	getUploadedCSV(): Promise<any[]> {
		return new Promise((resolve, reject) => {
			const url = `${this.API_URL}upload/csv?a=get_list`;
			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	updateFromUploadedCSVItem(uuid: string, analyse): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${this.API_URL}upload/csv?a=update_analyse&uuid=${uuid}`;

			const updates = analyse.map(item => {
				const changes: any = {};
				if (item.toLocation) {
					changes.location = item.toLocation;
				}
				if (item.toTitle) {
					changes.title = item.toTitle;
				}

				return { id: item.id, changes };
			});

			return this.http
				.post<any>(url, updates, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	analyseUploadedCSVItem(uuid: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${this.API_URL}upload/csv?a=analyse_one&uuid=${uuid}`;
			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	getUploadedCSVItem(uuid: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${this.API_URL}upload/csv?a=get_one&uuid=${uuid}`;
			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	uploadCSV(text: string, contentType: string, userFilename: string): Promise<any> {
		return new Promise((resolve) => {

			return this.http
				.get<any>(this.API_URL + 'upload/csv?ct=' + contentType + '&uf=' + userFilename, this.apiService.getUAOHeaders())
				.subscribe(b => {
					const xhr = new XMLHttpRequest();
					xhr.open('PUT', b.url);
					xhr.setRequestHeader('x-amz-acl', 'public-read');
					xhr.onload = function () {
						if (xhr.status === 200) {
							resolve(xhr.response);
						}
					};
					xhr.send(text);

					return;
				});
		});
	};
}
