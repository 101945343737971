import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Building } from 'app/classes/building';
import { License, LicenseBuilding, LicenseGateway } from 'app/classes/license';
import { Gateway } from 'app/classes/gateway';
import { LicenseCollection } from "app/classes/license-collection";
import { BillingClient } from 'app/classes/billing-client';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BillingService {
	API_URL = 'https://ynbl6m50m7.execute-api.eu-west-2.amazonaws.com/4d/';

	private _billingClients: BehaviorSubject<BillingClient[]> = new BehaviorSubject(null);
	public billingClients: Observable<BillingClient[]> = this._billingClients.asObservable();

	constructor(private apiService: APIService, private http: HttpClient) { }

	getBillingClients(): Promise<BillingClient[]> {
		return new Promise((resolve) => {
			if (this._billingClients.value === null) {
				this
					.get(`a=get_billing_clients`)
					.then(bcs => {
						bcs = bcs.clients.map(bc => new BillingClient(bc));
						this._billingClients.next(bcs);
						resolve(bcs);
					});
			} else {
				console.log('resolved from cache');
				resolve(this._billingClients.value);
			}
		});
	}

	clearBillingClientsCache() {
		this._billingClients.next(null);
	}

	postLicense(license: LicenseBuilding | LicenseGateway) {
		return new Promise((resolve, reject) => {
			let action: string;
			let payload: any = { license: license.serialise() };
			let obj: Gateway | Building;

			switch (license.licenseFor) {
				case 'building':
					action = 'update_building_license';
					obj = (<LicenseBuilding>license).building;
					payload.building = obj.serialise();
					break;
				case 'gateway':
					action = 'update_gateway_license';
					obj = (<LicenseGateway>license).gateway;
					payload.gateway = obj.serialise();
					break;
			}

			this.post(payload, `a=${action}&i=${obj.id}`)
				.then(response => {
					resolve(response);
				})
				.catch(err => reject(err));
		});
	}

	/** @deprecated */
	postBuildingLicense(building: Building, license: License) {
		return new Promise((resolve) => {

			this.post({ license: license.serialise(), building: building.serialise() }, `a=update_building_license&i=${building.id}`)
				.then(response => {
					resolve(response);
				});
		});
	}

	/** @deprecated */
	postGatewayLicense(gateway: Gateway, license: License) {
		return new Promise((resolve) => {

			this.post({ license: license.serialise(), gateway: gateway.serialise() }, `a=update_gateway_license&i=${gateway.id}`)
				.then(response => {
					resolve(response);
				});
		});
	}

	getLicensesForBuildingGateways(buildingId: number): Promise<Gateway[]> {
		return new Promise((resolve) => {
			this
				.get(`a=building_gateways_licenses&i=${buildingId}`)
				.then(gateways => {

					resolve(gateways.map(g => {
						const gateway = new Gateway({ id: g.gId, title: g.gTitle, status_id: g.gStatusId });
						if (g.id) {
							gateway.license = new License(g);
						}

						return gateway;
					}));
				});
		});
	}

	getLicensesForBuilding(buildingId: number): Promise<License[]> {
		return new Promise((resolve) => {
			this.get(`a=building_licenses&i=${buildingId}`)
				.then(licenses => {
					resolve(licenses.filter(l => l.id).map(l => new License(l)));
				});
		});
	}

	getForBuilding(buildingId: number): Promise<BillingBuilding> {
		return new Promise((resolve) => {
			this.get(`a=building&i=${buildingId}`).then(b => {
				resolve(new BillingBuilding(b));
			});
		});
	}

	getList(qs: string = ''): Promise<BillingBuilding[]> {
		return new Promise((resolve) => {
			this.get(`a=list&${qs}`).then(response => {
				resolve(response.map(b => new BillingBuilding(b)));
			});
		});
	}

	get(qs: string = ''): Promise<any> {
		return new Promise((resolve) => {
			const url = `${this.API_URL}?${qs}`;
			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	post(body: any, qs: string = ''): Promise<any> {
		return new Promise((resolve) => {
			const url = `${this.API_URL}?${qs}`;
			return this.http
				.post<any>(url, body, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(b);
				});
		});
	}

	postBillingClient(billingClient: BillingClient): Promise<any> {
		return new Promise((resolve) => {
			const payload = billingClient.serialise();
			this.post(payload, `a=post_billing_client`)
				.then(r => {
					this.clearBillingClientsCache();
					resolve(r.insertId ? r.insertId : r);
				});
		});
	}

	putFieldForLicense(field: string, value: string | Date | number | null, licenseId: number): Promise<any> {
		return new Promise((resolve) => {
			const payload = { field, value };
			this.post(payload, `a=put_license&i=${licenseId}`)
				.then(r => {
					resolve(r);
				});
		});
	}

	async getLicenseCollection(qs: string = ''): Promise<LicenseCollection> {
		return new Promise(async (resolve) => {
			return this.get(`a=get_analytics&${qs}`)
				.then(b => {
					resolve(new LicenseCollection(b));
				});
		});
	}

	getAudit(qs: string = ''): Promise<any> {
		return new Promise((resolve) => {
			return this.get(`a=get_audit&${qs}`)
				.then(b => {
					resolve(b.audit);
				});
		});
	}

	async getInvoices(qs: string = ''): Promise<Invoice[]> {
		return new Promise(async (resolve) => {
			this.get(`a=get_invoices&${qs}`)
				.then(invoices => {
					resolve(invoices.map(invoice => new Invoice(invoice)));
				});
		});
	}

	async getConsumption(qs: string = ''): Promise<ConsumptionCollection> {
		return new Promise((resolve) => {
			const url = `${this.API_URL}consumption?${qs}`;

			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(new ConsumptionCollection(b));
				});
		});
	}

	async ingestConsumption(): Promise<ConsumptionCollection> {
		return new Promise((resolve) => {
			const url = `${this.API_URL}consumption/ingest`;

			return this.http
				.get<any>(url, this.apiService.getUAOHeaders())
				.subscribe(b => {
					resolve(new ConsumptionCollection(b));
				});
		});
	}
}

export class ConsumptionCollection {
	assets: ConsumptionAsset[]

	constructor(data?: any) {
		this.assets = data;
	}
}

export class ConsumptionAsset {
	theirRef: string;
	accountType: 'gas' | 'electricity';
	items: any[];
	title: string;
	periods: any;
	constructor(data?: any) {

	}
}

export class Invoice {
	createdAt: Date;
	document: any;
	theirRef: string;
	accountType: 'gas' | 'electricity';

	isCreditNote: boolean;
	invoicedDate: Date;

	period: { startAt: Date, endAt: Date };

	totals: {
		netTotalCost: number,
		totalTax: number,
		totalCost: number,
	} = { netTotalCost: 0, totalCost: 0, totalTax: 0 };

	meter: { point: number, serial: string };


	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.createdAt = new Date(data.createdAt);
		this.theirRef = data.their_ref;
		this.document = data.document;
		this.accountType = data.document.accountType;
		this.invoicedDate = new Date(data.document.invoiceDate);
		this.totals = {
			netTotalCost: data.document.netTotalCost,
			totalTax: data.document.totalTax,
			totalCost: data.document.totalCost
		};
		this.period = {
			startAt: new Date(data.document.periodStartDate),
			endAt: new Date(data.document.periodEndDate),
		}
		this.meter = {
			point: data.document.meterPointNumber,
			serial: data.document.meterSerialNumber
		};

		this.isCreditNote = data.document.isCreditNote;
	}
}

export interface IGetBillingAudit {
	audit: any[];
}

export interface ILicenseCollection {
	gateways: any;
	buildings: any;
}

export class BillingBuilding {
	building: Building;
	billing: Billing;

	constructor(data: any) {
		this.building = new Building(data);
		this.billing = new Billing(data);
	}
}

export class Billing {
	id: number;
	licensedAt: Date;
	licenseValue: number;

	constructor(data: any) {
		this.licensedAt = new Date(data.licensedAt);
		this.licenseValue = data.licenseValue || data.value;
		this.id = data.license_id || data.id;
	}
}
