import { Injectable } from '@angular/core';
import { BuilderItem, BuilderItemRow } from 'app/classes/builderItem';
import { APIService } from 'app/shared/api.service';
import { StoreService } from 'app/shared/store.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CmsScreen } from "../../classes/screen";
import { WebPage } from '../classes/web-page';
@Injectable({
  providedIn: 'root'
})
export class AdminBuilderService {

  readonly LOCALSTORAGE_BUILDERITEM = ':builder:item';
  readonly API_URL = 'https://c95lgo8wu8.execute-api.eu-west-2.amazonaws.com/prod';

  private _action: BehaviorSubject<AdminActionEvent> = new BehaviorSubject(null);
  public action: Observable<AdminActionEvent> = this._action.asObservable();

  private _currentRow: BehaviorSubject<BuilderItemRow> = new BehaviorSubject(null);
  public currentRow: Observable<BuilderItemRow> = this._currentRow.asObservable();

  private _rowEvent: BehaviorSubject<BuilderRowEvent> = new BehaviorSubject(null);
  public rowEvent: Observable<BuilderRowEvent> = this._rowEvent.asObservable();

  private _orgId: number;

  constructor(private apiService: APIService, private http: HttpClient, private storeService: StoreService) { }

  setAction(action: AdminBuilderToolsAction, row?: BuilderItemRow) {
    this._action.next({ action, row });
  }

  setCurrentRow(row: BuilderItemRow) {
    this._currentRow.next(row);
  }

  setCurrentRowChanged(row: BuilderItemRow) {
    this._rowEvent.next({ row, action: 'changed' });
  }

  getfromLocalStorageBuilderItem(orgId?: number) {
    if (orgId) {
      this._orgId = orgId;
    }
    if (!this._orgId) {
      return;
    }
    const builderItemString = localStorage.getItem(this._orgId + this.LOCALSTORAGE_BUILDERITEM);

    return builderItemString ? new BuilderItem(builderItemString) : null;
  }

  setLocalStorageBuilderItem(builderItem: BuilderItem) {
    if (!this._orgId) {
      return;
    }
    localStorage.setItem(this._orgId + this.LOCALSTORAGE_BUILDERITEM, JSON.stringify(builderItem.serialise()));
  }

  getAllScreens(qs: string = ''): Promise<CmsScreen[]> {
    return new Promise((resolve) => {
      const url = `${this.API_URL}/screens?${qs}`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  getScreensForModule(module: string): Promise<any[]> {
    return new Promise((resolve) => {
      const url = `${this.API_URL}/screens?section=module&module=${module}`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(response => {
          resolve(response.screens);
        });
    });
  }

  async getWebPage(id: number, qs: string = ''): Promise<WebPage> {
    return new Promise((resolve) => {
      const url = `${this.API_URL}/screens?id=${id}&section=page&${qs}`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(response => {
          resolve(new WebPage(response.page));
        });
    });
  }

  get(qs: string = ''): Promise<any> {
    return new Promise((resolve) => {
      const url = `${this.API_URL}/screens?${qs}`;
      return this.http.get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  save(builderItem: BuilderItem) {
    if (!builderItem) {
      console.log('NO_BUILDER_ITEM');
      return;
    }
    return new Promise((resolve) => {
      const url = `${this.API_URL}/screens`;
      return this.http.post<any>(url, builderItem.serialise(), this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }
}

export interface AdminActionEvent {
  action: AdminBuilderToolsAction;
  data?: any;
  row?: BuilderItemRow;
}

export interface BuilderRowEvent {
  action: ActionChangeType;
  row?: BuilderItemRow;
  data?: any;
  uuid?: string;
}

export type AdminBuilderToolsAction = 'addRow' | 'addColumn' | 'deleteRow' | 'save';
export type ActionChangeType = 'changed' | 'delete';
