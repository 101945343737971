import { Component, OnInit } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { Gateway } from 'app/classes/gateway';
import { Site } from 'app/classes/site';
import { SetpointQueryDateRange } from 'app/setpoints/setpoint-query/setpoint-query.component';
import { APIService } from 'app/shared/api.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-gateway-view',
  templateUrl: './dialog-gateway-view.component.html',
  styleUrls: ['./dialog-gateway-view.component.css'],
  providers: [DialogService]
})
export class DialogGatewayViewComponent implements OnInit {
  site: Site;
  gatewayId: string;
  gateway: Gateway;
  assets: Asset[];

  constructor(public dialogService: DialogService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private apiService: APIService) {

  }

  ngOnInit(): void {
    if (this.config.data) {
      this.gatewayId = this.config.data.gatewayId;
      this.get();
    }
  }

  get() {
    this.apiService.getGateway(this.gatewayId).then(gateway => {
      if (!gateway) {
        this.apiService.toastWarn('No gateway in 4D', '');
        this.cancel();
      }
      this.gateway = gateway;
      this.apiService.getAssetsForGatewayId(this.gatewayId).then(assets => {
        this.assets = assets;
      });
    });
  }

  cancel() {
    this.ref.close();
  }
}
