import { Asset } from "./asset";

export class BuilderItem {
	id: number;
	idx: number = 0;
	screenId: number = 1;
	uuid: string;
	title: string;
	element: 'table' | 'flex' = 'table';
	alignment: 'left' | 'center' | 'right' = 'center';
	padding: number = 10;
	rows: BuilderItemRow[] = [];

	constructor(data?: any) {
		if (data) {
			if (typeof data === 'string') {
				data = JSON.parse(data);
			}
			this.id = data.id || 1;
			this.uuid = data.uuid || crypto.randomUUID();
			this.title = data.title;
			this.element = data.element;
			try {
				this.rows = data.rows.map(row => new BuilderItemRow(row));
			} catch (e) { console.log(e) }
		}

	}

	addRow() {
		const row = new BuilderItemRow();
		this.rows.push(row);

		return row;
	}

	deleteRow(row: BuilderItemRow) {
		this.rows = this.rows.filter(rowAtIndex => rowAtIndex.uuid !== row.uuid);

		return this.rows;
	}

	addColumnToRow(row: BuilderItemRow): BuilderItemRow {
		const index = this.rows.findIndex(rowAtIndex => rowAtIndex.uuid === row.uuid);
		if (index === -1) {
			return;
		}

		const rowAtIndex = this.rows[index];
		rowAtIndex.addColumn();

		return rowAtIndex;
	}

	updateRow(row: BuilderItemRow) {
		const index = this.rows.findIndex(rowAtIndex => rowAtIndex.uuid === row.uuid);
		if (index >= 0) {
			this.rows[index] = row;
		}
	}

	getAssetIds(): number[] {
		const ids = [];
		this.rows.forEach(row => row.columns.forEach(column => column.assetId ? ids.push(column.assetId) : null));

		return ids;
	}

	updateAssets(assets: Asset[]) {
		this.rows.forEach(row => row.columns.forEach(column => {
			assets.forEach(asset => {
				if (+asset.id === +column.assetId) {
					column.asset = asset;
				}
			});
		}));
	}

	updateValues(assetValues: { id: number, value: number | string }[]) {
		this.rows.forEach(row => row.columns.forEach(column => {
			assetValues.forEach(assetValue => {
				if (+assetValue.id === +column.assetId) {
					column.asset.value = '' + assetValue.value;
				}
			});
		}));
	}

	serialise() {
		return {
			id: this.id,
			uuid: this.uuid,
			idx: this.idx,
			alignment: this.alignment,
			element: this.element,
			title: this.title,
			screenId: this.screenId,
			rows: this.rows.map(row => row.serialise())
		}
	}
}

export class BuilderItemRow {
	id: number;
	uuid: string;
	title: string;
	columns: BuilderItemColumn[] = [];

	constructor(data?: any) {
		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.title = data.title;
			this.columns = data.columns.map(col => new BuilderItemColumn(col));
		} else {
			this.title = 'New Row';
			this.uuid = crypto.randomUUID();
		}

	}

	deleteColumn(column: BuilderItemColumn): BuilderItemColumn[] {
		this.columns = this.columns.filter(col => col.uuid !== column.uuid);

		return this.columns;
	}

	addColumn(): BuilderItemColumn {
		const column = new BuilderItemColumn({ title: 'New Column' });
		this.columns.push(column);

		return column;
	}

	changeColumnProperty(column: BuilderItemColumn, property: string, value: any): BuilderItemColumn {
		const index = this.columns.findIndex(cAtIndex => cAtIndex.uuid === column.uuid);
		if (index >= 0) {
			this.columns[index][property] = value;
		}
		if (property === 'assetId') {
			this.columns[index].asset = null;
		}

		return this.columns[index];
	}

	serialise() {
		return {
			id: this.id,
			uuid: this.uuid,
			title: this.title,
			columns: this.columns.map(col => col.serialise())
		}
	}
}

export class BuilderItemColumn {
	id: number;
	uuid: string;
	title: string;
	isRotated: boolean;
	height: number;
	width: number;
	assetId: number;
	asset: Asset = new Asset();

	constructor(data?: any) {
		if (data) {
			this.title = data.title;
			this.id = data.id;
			this.uuid = data.uuid;
			this.height = data.height;
			this.width = data.width;
			this.isRotated = data.isRotated;
			this.assetId = data.assetId;
		}
		this.uuid = data?.uuid || crypto.randomUUID();
	}

	setRotated(deg: number) {
		this.isRotated = true;
		this.height = 100;
	}

	css() {
		if (this.isRotated) {
			return 'transform: rotate(270deg);';
		}
	}

	serialise() {
		return {
			id: this.id,
			uuid: this.uuid,
			title: this.title,
			isRotated: this.isRotated,
			height: this.height,
			width: this.width,
			assetId: this.assetId
		}
	}
}
