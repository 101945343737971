import { DataForm } from "app/layout/data-form/data-form.component";

export class Condition {
	id: number;
	uuid: string;
	target: 'asset';
	value: number;
	operand: OperandType;
	timespan: { from: string, to: string };
	// Allow the last telemetry to be included that was before the time.
	includePreviousMins: number = 0;

	constructor(data?: any) {
		if (data) {

			if (data instanceof DataForm) {
				// Convert a dataform to a condition
				this.uuid = <string>data.id;
				this.timespan = { from: null, to: null };
				for (let index = 0; index < data.items.length; index++) {
					const row = data.items[index];
					switch (row.id) {
						case 'value':
							this.value = <number>row.value;
							break;
						case 'operand':
							this.operand = <OperandType>row.value;
							break;
						case 'timespan.from':
							this.timespan.from = <string>row.value;
							break;
						case 'timespan.to':
							this.timespan.to = <string>row.value;
							break;
						case 'includePreviousMins':
							this.includePreviousMins = <number>row.value;
							break;
					}

				}
			} else {
				this.id = data.id;
				this.operand = data.operand;
				this.target = data.target;
				this.timespan = { from: '08:00', to: '09:00' };
				this.value = data.value;
				this.uuid = this.uuid || data.uuid || crypto.randomUUID();
				this.includePreviousMins = data.includePreviousMins || 0;
			}
		} else {
			this.uuid = crypto.randomUUID();
			this.timespan = { from: '08:00', to: '09:00' };
		}
	}
}


export type OperandType = 'gt' | 'gte' | 'lt' | 'lte' | 'eq';
