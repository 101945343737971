<article>
	<main>
		<section *ngIf="gateway">
			<div>
				<h3>{{gateway.title}}</h3>
			</div>
			<div><span>Address</span>{{gateway.site.address.address1}} {{gateway.site.address.addressTown}}
				{{gateway.site.address.addressCounty}} {{gateway.site.address.addressPostcode}}</div>
			<div><span>Org</span>{{gateway.org.title}}</div>
		</section>
		<section>
			<h4>Assets</h4>
			<table class="table">
				<thead>
					<tr>
						<th>#</th>
						<th>Title</th>
						<th>Identifier</th>
						<th>Type</th>
						<th>Value</th>
						<th>Updated</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let asset of assets">
						<td>{{asset.id}}</td>
						<td>{{asset.title}}</td>
						<td>{{asset.identifier}}</td>
						<td pTooltip="{{asset.assetType_id}}" showDelay="2000">{{asset.assetTypeTitle}}</td>
						<td>{{asset.value}}</td>
						<td>{{asset.updatedAt | date:'dd MMM YY HH:mm'}}</td>
					</tr>
				</tbody>
			</table>
		</section>
	</main>
	<footer>
	</footer>
</article>
