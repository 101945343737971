export class WebPage {
	id: number;
	title: string;
	screenId: number;
	moduleId: number;
	children: WebPage[];

	constructor(data?: any) {
		if (data) {
			this.id = data.id;
			this.title = data.title;
			this.children = data.children ? data.children.map(child => new WebPage(child)) : [];
			this.screenId = data.screen_id || data.screenId;
			this.moduleId = data.module_id || data.moduleId;
		}
	}

	serialise() {
		return {
			id: this.id,
			title: this.title
		}
	}
}
